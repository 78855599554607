import { useState, useEffect } from 'react'

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(true)

  const ifIsMobile = () => {
    if (
        navigator?.connection?.saveData ||
        !matchMedia('(min-width: 992px)').matches
      ) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
  }

  useEffect(() => {
      window.addEventListener("resize", ifIsMobile);
      // Call handler right away so state gets updated with initial window size
      ifIsMobile();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", ifIsMobile);
  }, [])

  return isMobile
}

export default useIsMobile