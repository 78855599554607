import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';

import { mqMin } from '@sentry/static-shared/utils/css';
import { white } from '@sentry/static-shared/utils/css/colors';
import { borderRadius } from '@sentry/static-shared/utils/css/constants';
import Layer from '@sentry/static-shared/components/Layer';
import Prose from '@sentry/static-shared/components/Prose';
import CustomMDXRenderer from '@sentry/static-shared/components/CustomMDXRenderer';

import Layout from 'components/layout';
import SEO from 'components/seo';
import { useLocation } from "@reach/router";
import { canonicalURL } from '@sentry/static-shared/utils/CanonicalLink';
import DocHeader from 'components/doc-header';

const DocLayout = ({ data }) => {
  const {
    mdx: { frontmatter, body },
  } = data;

  const locationSlug = useLocation();
  const canonicalLink = canonicalURL('open', locationSlug.pathname);

  return (
    <Layout>
      <SEO 
        title={frontmatter.title} 
        description={frontmatter.excerpt}
        canonical={canonicalLink}
      />
      <DocHeader />
      <Layer disablePaddingTop={true} background="light" tag="main">
        <StyledBackplate>
          <Prose>
            <h1>{frontmatter.title}</h1>

            <CustomMDXRenderer body={body} />
          </Prose>
        </StyledBackplate>
      </Layer>
    </Layout>
  );
};

const StyledBackplate = styled.div`
  background-color: ${white};
  border-radius: ${borderRadius};
  padding: 1rem;

  ${mqMin('md')} {
    padding: 2rem;
  }

  h1,
  h2,
  h3,
  p,
  ul,
  ol {
    max-width: 55rem;
  }
`;

export default DocLayout;

export const pageQuery = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        excerpt
      }
    }
  }
`;
