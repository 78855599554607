
import styled from '@emotion/styled';

import TriangleShape from '@sentry/static-shared/images/banner/triangle-banner-shape.svg';
import WaveShape from '@sentry/static-shared/images/banner/wave-banner-shape.svg';


const BackgroundTexture = () => (
    <StyledTextureContainer>
        <TriangleShape />
        <WaveShape />
    </StyledTextureContainer>
);

const StyledTextureContainer = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  > svg {
    position: absolute;

    &:first-of-type {
      bottom: -70px;
      left: -70px;
    }

    &:nth-of-type(2) {
      right: -120px;
      top: -120px;
    }
  }
`;

export default BackgroundTexture;