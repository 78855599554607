import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Button from '@sentry/static-shared/components/Button';
import { mqMin } from '@sentry/static-shared/utils/css';
import { mdViolet } from '@sentry/static-shared/utils/css/colors';
import { buttonHorizontalPadding } from '@sentry/static-shared/utils/css/constants';
import Layer, {
  StyledBackground,
} from '@sentry/static-shared/components/Layer';

const DocHeader = props => (
  <StyledDocHeader
    disablePaddingTop={true}
    disableClipping={true}
    background="light"
  >
    <div
      css={css`
        display: flex;
        flex-grow: 1;
      `}
    >
      <StyledBreadCrumbs>
        <Button to="/" variant="silent">
          ← Back to the main page
        </Button>
      </StyledBreadCrumbs>
    </div>
  </StyledDocHeader>
);

const angle = 3.49;
const paddingDesktop = 1.5;
const compensateDesktop = `calc(${paddingDesktop}rem + ${angle}vw)`;

const StyledDocHeader = styled(Layer)`
  color: ${mdViolet};

  ${() => StyledBackground} {
    padding-top: 1em;
    padding-bottom: 1em;

    ${mqMin('md')} {
      padding-top: 1.5em;
      padding-bottom: 1.5em;
    }
  }

  ${mqMin('md')} {
    &.overlapLeft,
    &.overlapRight {
      ${() => StyledBackground} {
        padding-bottom: ${({ disablePaddingBottom }) =>
          disablePaddingBottom ? null : compensateDesktop} !important;
      }
    }
  }
`;

const StyledBreadCrumbs = styled.div`
  margin-right: auto;
  display: flex;
  margin-left: -${buttonHorizontalPadding};
`;

export default DocHeader;
