import React from 'react';
import styled from '@emotion/styled';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Button from '@sentry/static-shared/components/Button';
import BackgroundTexture from '@sentry/static-shared/components/molecules/textures/BackgroundTexture';
import { mqMin } from '@sentry/static-shared/utils/css';
import { gradientPurple, blurple, gradientSunrise, gradientYellow, gradientWarm } from '@sentry/static-shared/utils/css/colors';
import { borderRadius } from '@sentry/static-shared/utils/css/constants';
import useIsMobile from '@sentry/static-www/src/hooks/useIsMobile';
import FlexGrid, { FlexGridCell } from '../FlexGrid';

const PromotionalBanner = ({ background = "Warm", subtitle, content, buttonLabel, buttonTo, image, description, date, isTextured = false }) => {
  const backgroundMap = {
    "Blurple": blurple,
    "Peach": gradientSunrise,
    "Yellow": gradientYellow,
    "Purple": gradientPurple,
    "Warm": gradientWarm
  }

  const lightBackgrounds = ["Peach", "Yellow", "Warm"];

  let isMobile = useIsMobile();

  const ImageComponent = () => {
    return image?.file
      ? <StyledImage src={image.file.url} alt={image.description} />
      : image.childImageSharp
        ? <StyledGatsbyImage image={getImage(image)} alt={image.alt} />
        : <StyledImage src={image} />
  };

  return (
    <StyledPromotionalBanner islightTheme={lightBackgrounds.includes(background)} background={backgroundMap[background]} isTextured={isTextured}>
      <FlexGrid>
        <ImageCellMobile>
          <ImageComponent/>
        </ImageCellMobile>
        <FlexGridCell lg={4}>
          <StyledBannerContent isTextured={isTextured} className={!image ? 'textOnly' : ''}>
            {subtitle && <p className='subtitle'>{subtitle}</p>}
            <h2>{content}</h2>
            {isTextured ? (
              <p>{description}</p>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}
            {date &&
              <p className="date">{date}</p>
            }
            <Button
              to={buttonTo}
              variant={lightBackgrounds.includes(background) ? 'primary-light' : 'primary-dark'}
              redesign
            >{buttonLabel}</Button>
          </StyledBannerContent>
        </FlexGridCell>
        <ImageCellDesktop lg={8}>
          <ImageComponent/>
        </ImageCellDesktop>
      </FlexGrid>
      {isTextured &&
        <BackgroundTexture />
      }
    </StyledPromotionalBanner>
  )
};

export default PromotionalBanner;

const ImageCellDesktop = styled(FlexGridCell)`
  display: none;
  ${mqMin('lg')}{
    display: flex;
  }
  align-self: center;
`;

const ImageCellMobile = styled(FlexGridCell)`
  display: flex;
  ${mqMin('lg')}{
    display: none;
  }
`;

const StyledPromotionalBanner = styled.div`
  align-items: center;
  background: ${props => props.background || backgroundMap["Warm"]};
  border-radius: ${borderRadius};
  color: ${props => props.islightTheme ? "#1F1633" : "#FFF"};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  margin: 1rem auto;
  max-width: 400px;
  padding: ${props => props.isTextured ? "2.5rem 2rem" : "1rem"};
  position: relative;

  ${mqMin('lg')} {
    flex-direction: row;
    margin-bottom: 2rem;
    max-width: 100%;
    padding: ${props => props.isTextured ? "2.5rem 2rem" : "1.25rem 2rem"};
  }

   img {
     object-fit: contain;
     order: 0;
     width: 100%;

     ${mqMin('lg')} {
       order: 1;
     }
   }
`;

const StyledBannerContent = styled.div`
  align-items: ${props => props.isTextured ? "center" : "flex-start"};
  display: flex;
  flex-direction: column;
  text-align: ${props => props.isTextured ? "center" : "left"};

  flex-basis: ${props => props.isTextured ? "calc(60% - 0.5rem)" : "calc(50% - 0.5rem)"};

  &.textOnly {
    flex-basis: 100%;
  }

  > * {
    margin: 1rem 0 0;
  }

  .subtitle {
    line-height: 1;
    text-transform: uppercase;
  }

  .date {
    font-weight: bold;
  }


  h2 {
    text-transform: capitalize;
    white-space: balance;

  }

  p {
    line-height: 1.5;
  }

  a {
    margin-top: 1.5rem;
  }
`;

const StyledImage = styled.img`
  object-fit: contain;
  order: 0;
  width: 100%;

  ${mqMin('lg')} {
    order: 1;
    width: auto;
    max-width: 100%;
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  object-fit: contain;
  order: 1;
  width: 100%;

  ${mqMin('lg')} {
    order: 1;
    flex-basis: ${props => props.isTextured ? "calc(40% - 0.5rem)" : "calc(50% - 0.5rem)"};
  }
`;